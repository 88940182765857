import React, { useRef } from 'react'
import {
  Card,
  Form,
  Button,
  Modal,
  Select,
  Space,
  Radio,
  Table,
  Drawer,
  ConfigProvider,
  Breadcrumb,
  Input,
  message,
  Tooltip,
} from 'antd'
import styles from './index.module.scss'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addComps,
  batchDel,
  changeStaus,
  delComps,
  editComps,
  getCompList,
  getCompMode,
  getCompStatus,
  getDicItem,
  getTrigger,
} from '../../../../store/actions/comp'
import { useState } from 'react'
import zhCH from 'antd/lib/locale/zh_CN'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getCompany } from '../../../../utils/cookie'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
export default function CompManage() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [delcomp, setDelComp] = useState(false)
  const [radio, setRadio] = useState(false)
  const [coList, setCoList, getCoList] = useGetState({})
  const [isChange, setIsChange] = useState(false)
  const [batchCompDel, setBatchCompDel] = useState(false)
  const [watch, setWatch] = useState(false)
  const tableRef = useRef(null)
  const [coTypeCode, setCoTypeCode] = useState('')
  const size = useSize(tableRef)
  const rulesObj = useRef({
    co_type_code: [
      {
        required: true,
        message: '请选择企业类型',
      },
    ],
    co_code: [
      {
        required: true,
        message: '企业编号不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getCoList().co_id ? getCoList().co_id : null,
                field: 'code',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('企业编号已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    co_name: [
      {
        required: true,
        message: '企业名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getCoList().co_id ? getCoList().co_id : null,
                field: 'name',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('企业名称已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    co_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })
  //获取企业列表
  useEffect(() => {
    dispatch(
      getCompList({
        exclude_template: true,
      })
    )
  }, [dispatch])
  // 获取企业类型
  useEffect(() => {
    dispatch(getDicItem({ co_id: getCompany(), dic_code: 'SYS_CO_TYPE' }))
  }, [dispatch])
  // 获取企业状态
  useEffect(() => {
    dispatch(getCompStatus({ co_id: getCompany(), dic_code: 'SYS_CO_STATUS' }))
  }, [dispatch])
  // 获取企业模板
  useEffect(() => {
    dispatch(getCompMode({ co_type_code: 'PROD_TEMPLATE', is_page: true }))
  }, [dispatch])
  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        ...coList,
        co_type_code: coList?.co_type_code
          ? coList?.co_type_code
          : coTypeCode
          ? coTypeCode
          : null,
      })
    }, 0)
  }, [coList, coTypeCode])
  const { total, compList, dicItem, compMode, compStatus } = useSelector(
    (state) => state.comp
  )
  const dicItemFilter = dicItem.filter(
    (item) => item.dic_item_code !== 'PROD_TEMPLATE'
  )

  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render(text, record, index) {
        return (
          <span>
            {(paramsRef.current.page - 1) * paramsRef.current.page_size +
              index +
              1}
          </span>
        )
      },
    },
    {
      title: '企业编号',
      dataIndex: 'co_code',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '企业名称',
      dataIndex: 'co_name',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '企业类型',
      dataIndex: 'co_type_name',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '状态',
      dataIndex: 'co_status_name',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render(text, i, o) {
        return text === '正常' ? (
          <>
            <span className="zc"> </span>
            <span> {text}</span>
          </>
        ) : (
          <>
            <span className="ty"> </span>
            <span> {text}</span>
          </>
        )
      },
    },
    {
      title: '企业描述',
      dataIndex: 'co_desc',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: '是否预置',
      dataIndex: 'is_preset',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render(is_preset) {
        return is_preset ? (
          <div className="is-true">是</div>
        ) : (
          <div className="is-false">否</div>
        )
      },
    },
    {
      title: '创建时间',
      dataIndex: 'created_time',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '修改时间',
      dataIndex: 'updated_time',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      key: 'co',
      width: 200,
      fixed: 'right',
      render(co) {
        return (
          !co.is_preset && (
            <Space>
              <Button
                type="link"
                className="edit"
                onClick={() => watchComp(co)}
              >
                查看
              </Button>
              <Button
                className={{
                  start: co.co_status_code === 'SUSPEND',
                  stop: co.co_status_code === 'NORMAL',
                }}
                type="link"
                onClick={() => changeState(co)}
              >
                {co.co_status_code === 'SUSPEND' ? '启用' : '停用'}
              </Button>
              <Button type="link" className="edit" onClick={() => editComp(co)}>
                修改
              </Button>
              <Button
                type="link"
                className="del"
                onClick={() => {
                  delComp(co)
                }}
              >
                删除
              </Button>
            </Space>
          )
        )
      },
    },
  ]

  // 新增企业弹框
  const addComp = () => {
    setVisible(true)
    setCoList({})
  }
  const formRef = useRef(null)
  //修改企业
  const editComp = (co) => {
    co = JSON.parse(JSON.stringify(co))
    setCoList(co)
    setVisible(true)
  }

  // 查看企业
  const watchComp = (co) => {
    setCoList(co)
    setWatch(true)
  }

  // 删除企业弹框并获取企业信息并保存
  const delComp = (co) => {
    setCoList(co)
    setDelComp(true)
  }
  const [batchId, setBatchId] = useState('')
  const batchDels = async (id) => {
    await dispatch(batchDel(id))
    setBatchCompDel(false)
    setBatchId('')
    message.success('批量删除成功')
    paramsRef.current.page = 1
    dispatch(getCompList({ exclude_template: true }))
  }
  // 批量删除
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBatchId(`${selectedRowKeys}`)
    },
  }

  // 新增企业
  const onFinish = async (values) => {
    if (coList.co_id) {
      const res = await dispatch(editComps({ ...values, id: coList.co_id }))
      if (res.data.code === 0) {
        message.success('修改企业成功')
      } else {
        message.error(res.data.msg)
      }
    } else {
      const res = await dispatch(
        addComps({ ...values, co_status_code: 'NORMAL' })
      )
      console.log(res)
      if (res.data.code === 0) {
        message.success('新增企业成功')
      } else {
        message.error(res.data.msg)
      }
    }
    onReset()
    setVisible(false)
    paramsRef.current.page = 1
    await dispatch(
      getCompList({
        exclude_template: true,
        co_type_code: coTypeCode ? coTypeCode : null,
      })
    )
  }
  // 删除企业
  const delCompl = async (id) => {
    await dispatch(delComps(id))
    setDelComp(false)
    message.success('删除企业成功')
    paramsRef.current.page = 1
    onReset()
    dispatch(
      getCompList({
        exclude_template: true,
        co_type_code: coTypeCode ? coTypeCode : null,
      })
    )
  }
  // 搜索企业
  const searchComp = async (values) => {
    setCoTypeCode(values.co_type_code)
    paramsRef.current.co_type_code = values.co_type_code
    paramsRef.current.co_status_code = values.co_status_code
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(
      getCompList({ ...paramsRef.current, exclude_template: true })
    )
  }
  // 分页处理
  const onChange = (page, pageSize) => {
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getCompList({ ...paramsRef.current, exclude_template: true }))
  }
  const [coType, setCoType] = useState('')
  const onSelect = (value) => {
    setCoType(value)
  }
  // 启用停用处理
  const changeState = (co) => {
    setIsChange(true)
    setCoList(co)
  }
  const changeStatu = async (id) => {
    if (coList.co_status_code === 'SUSPEND') {
      await dispatch(changeStaus(id, { co_status_code: 'NORMAL' }))
      message.success('已启用')
      setIsChange(false)
    }
    if (coList.co_status_code === 'NORMAL') {
      await dispatch(changeStaus(id, { co_status_code: 'SUSPEND' }))
      message.success('已停用')
      setIsChange(false)
    }
    paramsRef.current.page = 1
    onReset()
    await dispatch(getCompList({ exclude_template: true }))
  }
  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    setCoTypeCode('')
  }
  const renderCell = (checked, record, index, originNode) => {
    console.log(checked, record, index, originNode)
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>企业管理</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="search-card">
          <Form
            form={form}
            autoComplete="off"
            className="form-search"
            onFinish={searchComp}
          >
            <div className="flex">
              <Form.Item label="企业类型" name="co_type_code">
                <Select placeholder="全部" style={{ width: 238 }}>
                  {dicItemFilter.map((item) => {
                    return (
                      <Select.Option
                        value={item.dic_item_code}
                        key={item.dic_item_id}
                      >
                        {item.dic_item_name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              {/* 'NORMAL' 'SUSPEND' */}
              <Form.Item label="企业状态" name="co_status_code">
                <Select placeholder="全部" style={{ width: 238 }}>
                  {compStatus.map((item) => (
                    <Select.Option value={item.dic_item_code}>
                      {item.dic_item_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="企业编号/名称" name="fuzzy_name">
                <Input
                  placeholder="请输入企业编号或企业名称"
                  style={{ width: 238 }}
                ></Input>
              </Form.Item>
            </div>
            <Form.Item>
              <Space size={[20]}>
                <MyButton type="default" htmlType="submit">
                  查询
                </MyButton>
                <MyButton onClick={onReset}>重置</MyButton>
              </Space>
            </Form.Item>
          </Form>
        </Card>
        <Space size={[24]} className="space-heigth">
          <MyButton type="default" onClick={addComp}>
            + 新增企业
          </MyButton>
          <MyButton
            type="default"
            onClick={() => {
              if (!batchId) return message.error('请至少勾选一条数据')
              setBatchCompDel(true)
            }}
          >
            批量删除
          </MyButton>
        </Space>
        <div className="right_table" ref={tableRef}>
          <ConfigProvider locale={zhCH}>
            <Table
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey="co_id"
              rowSelection={{
                ...rowSelection,
                getCheckboxProps(value) {
                  return {
                    disabled: value.is_preset,
                  }
                },
              }}
              columns={columns}
              dataSource={compList}
              showCount
              renderCell={renderCell}
              pagination={paginations}
            ></Table>
          </ConfigProvider>
        </div>
      </div>
      {/* 新增企业 */}
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={coList.co_id ? '修改企业' : '新增企业'}
        placement="right"
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
      >
        <Form
          autoComplete="off"
          validateTrigger={['onBlur', 'onChange']}
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            is_default_tmpl: false,
          }}
          onFinish={onFinish}
        >
          <div className="form-btn">
            <div className="flex">
              <Form.Item
                className="w-50"
                label="企业类型"
                name="co_type_code"
                rules={rulesObj.current.co_type_code}
              >
                <Select
                  disabled={coList.co_id ? true : false}
                  placeholder="请选择"
                  onSelect={onSelect}
                >
                  {dicItemFilter.map((item) => {
                    return (
                      <Select.Option
                        value={item.dic_item_code}
                        key={item.dic_item_id}
                      >
                        {item.dic_item_name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                className="w-50"
                label="企业编号"
                name="co_code"
                rules={rulesObj.current.co_code}
              >
                <Input
                  width={264}
                  maxLength={50}
                  placeholder="请输入企业编号，不超过50个字"
                ></Input>
              </Form.Item>
            </div>
            <Form.Item
              label="企业名称"
              name="co_name"
              rules={rulesObj.current.co_name}
            >
              <Input
                maxLength={50}
                placeholder="请输入企业名称，不超过50个字"
              ></Input>
            </Form.Item>
            <Form.Item
              label="企业描述"
              name="co_desc"
              rules={rulesObj.current.co_desc}
            >
              <Input.TextArea
                maxLength={500}
                showCount
                placeholder="请输入企业描述，不超过500个字"
                style={{ height: '100px' }}
              ></Input.TextArea>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.co_type_code !== currentValues.co_type_code
              }
            >
              {({ getFieldValue }) => {
                let co_type_code = getFieldValue('co_type_code')
                if (co_type_code === 'FORMAL_CORP') {
                  return (
                    <Form.Item label="产品配置模板" name="prods">
                      <Select
                        placeholder="请选择产品配置模板"
                        mode="multiple"
                        allowClear
                      >
                        {compMode?.map((item) => (
                          <Select.Option value={item.co_id} key={item.co_id}>
                            {item.co_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }
              }}
            </Form.Item>
          </div>

          <Form.Item>
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton type="default" htmlType="submit">
                  {coList.co_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      {/* 删除企业对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelComp(false)}
        title="删除企业"
        visible={delcomp}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelComp(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => delCompl(coList.co_id)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>
          <ExclamationCircleOutlined
            style={{ fontSize: 36, color: '#fe545f' }}
          />
        </p>
        <p>确定要删除企业"{coList.co_name}"吗？</p>
        <p>删除企业后将删除该企业下所有组织和用户</p>
      </Modal>

      {/* 批量删除企业对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setBatchCompDel(false)}
        title="批量删除企业"
        visible={batchCompDel}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setBatchCompDel(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => batchDels(batchId)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>删除所选企业后将删除所选企业下所有组织和用户</p>
        <p>确定要删除所选企业吗？</p>
      </Modal>

      {/* 停用启用企业 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setIsChange(false)}
        title={coList.co_status_code === 'SUSPEND' ? '启用' : '停用'}
        visible={isChange}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setIsChange(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => changeStatu(coList.co_id)}>
              {coList.co_status_code === 'SUSPEND' ? '启用' : '停用'}
            </MyButton>
          </Space>,
        ]}
      >
        <p>
          确定要{coList.co_status_code === 'SUSPEND' ? '启用' : '停用'}企业"
          {coList.co_name}"吗？
        </p>
        <p>
          {coList.co_status_code === 'SUSPEND' ? '启用' : '停用'}企业后将
          {coList.co_status_code === 'SUSPEND' ? '启用' : '停用'}
          该企业下所有组织和用户
        </p>
      </Modal>
      {/* 查看企业 */}
      <Drawer
        destroyOnClose={true}
        width={624}
        title="查看企业"
        placement="right"
        onClose={() => setWatch(false)}
        visible={watch}
      >
        <div style={{ minHeight: 'calc(100% - 30px)' }}>
          <div className="msg">
            <span className="linear"></span> 基本信息
          </div>
          <div className="watch_marg">
            <p>
              <span>企业类型</span>
              {coList.co_type_name}
            </p>

            <p>
              <span>企业编号</span>
              {coList.co_code}
            </p>
            <p>
              <span>企业名称</span>
              {coList.co_name}
            </p>
            <p className="desc">
              <span>企业描述</span>
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                className="desc_text"
                title={coList.co_desc}
              >
                {coList.co_desc || '-'}
              </Tooltip>
            </p>
            {coList.co_type_code !== 'ADMIN_GROUP' && (
              <p>
                <span>产品配置模板：</span>
                {coList.prod_names}
              </p>
            )}
          </div>
        </div>
        <MyButton
          type="default"
          className=" ant-spaces"
          onClick={() => setWatch(false)}
        >
          关闭
        </MyButton>
      </Drawer>
    </div>
  )
}
