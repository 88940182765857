import { GETUPDATELOGLIST } from '../constants'

const init = {
  total: 0,
  updatelogList: [],
}

export default function getUpdateLogList(state = init, action) {
  if (action.type === GETUPDATELOGLIST) {
    return {
      ...state,
      total: action.payload.total,
      updatelogList: action.payload.data,
    }
  }
  return state
}
