import React from 'react'
import {
  Card,
  Form,
  Button,
  Modal,
  Space,
  Table,
  Drawer,
  ConfigProvider,
  Breadcrumb,
  Input,
  TreeSelect,
  Select,
  Transfer,
  Tooltip,
  message,
} from 'antd'
import styles from './index.module.scss'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import zhCH from 'antd/lib/locale/zh_CN'
import {
  addUser,
  batchDel,
  delUsers,
  editUser,
  getTrigger,
  getUserInfo,
  getUserList,
  resetPassword,
} from '../../../../store/actions/user'
import { getOrgTreeList } from '../../../../store/actions/org'
import { getPermissionLists } from '../../../../store/actions/permission'
import { useRef } from 'react'
import { getCompLists } from '../../../../store/actions/comp'
import { getCompany, getOaOrg, getSuperGroup } from '../../../../utils/cookie'
import { useGetState, useSize } from 'ahooks'
import MyButton from '../../../../components/MyButton'
export default function Article() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [watchUser, setWatchUser] = useState(false)
  const [user, setUser] = useState({})
  const userForm = useRef()
  const [userInfos, setUserInfos, getUserInfos] = useGetState({})
  const [delModal, SetDelModal] = useState(false)
  const [orgId, setOrgId] = useState('')
  const [orgIds, setOrgIds] = useState('')
  const [batchAdd, SetBatchAdd] = useState('')
  const [compId, setCompId, getCompId] = useGetState(getCompany())
  const [resetModal, setResetModal] = useState(false)
  const [orgData, setOrgData] = useState([])
  const [userCoId, setUserCoId] = useState('')
  // 穿梭框
  const [targetKeys, setTargetKeys] = useState([])
  const [batchUserDel, setBatchUserDel] = useState(false)
  const tableRef = useRef(null)
  const size = useSize(tableRef)

  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '请选择企业/产品',
      },
    ],
    username: [
      {
        required: true,
        message: '用户名不能为空',
      },
      {
        pattern: /^(\D)[^\s]+(\s+[^\s]+)*.{2,20}$/,
        message: '4~20个字符，不能以数字或空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getUserInfos().user ? getUserInfos().user.id : null,
                // co_id: getCompId() ? getCompId() : getUserInfos().co_id,
                field: 'name',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('用户名已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    email: [
      {
        pattern:
          /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
        message: '邮箱地址不合法',
      },
    ],
    mobile_no: [{ pattern: /^1[3-9]\d{9}$/, message: '手机号格式不对' }],
    full_name: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    nickname: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    employee_no: [
      {
        required: getOaOrg() === 'true' ? true : false,
        message: '工号不能为空',
      },
    ],
  })

  const handleChange = (targetKeys) => {
    SetBatchAdd(targetKeys)
    setTargetKeys(targetKeys)
  }

  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  const { total, userList, userInfo } = useSelector((state) => state.user)
  const { orgTreeListCope } = useSelector((state) => state.org)

  const { compListAll } = useSelector((state) => state.comp)

  const { permissionListAll } = useSelector((state) => state.permission)

  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  const columns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '用户名',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text.username}>
                {text.username}
              </Tooltip>
            ),
          },
          {
            title: '手机号',
            dataIndex: 'mobile_no',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              const user = record.user
              return (
                <Tooltip placement="leftTop" title={user.mobile_no}>
                  {user.mobile_no}
                </Tooltip>
              )
            },
          },
          {
            title: '姓名',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '昵称',
            dataIndex: 'nickname',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '邮箱',
            dataIndex: 'email',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '工号',
            dataIndex: 'employee_no',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '是否预置',
            dataIndex: 'is_preset',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render(is_preset) {
              return is_preset ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '部门',
            dataIndex: 'orgs',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (i, text, o) => {
              const t = text.user
              return (
                <Tooltip placement="leftTop" title={t.orgs_name}>
                  {t.orgs_name[0]}
                </Tooltip>
              )
            },
          },
          {
            title: '角色',
            dataIndex: 'roles',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (i, text, o) => {
              const t = text.user
              return (
                <Tooltip placement="leftTop" title={t.role_name}>
                  {t.role_name}
                </Tooltip>
              )
            },
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'user',
            width: 220,
            fixed: 'right',
            render(user) {
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      paramsRef.current.page = 1
                      watchUserInfo(user)
                      setUserInfos(user)
                    }}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => showReset(user)}
                  >
                    重置密码
                  </Button>
                  {!user.is_preset && (
                    <>
                      <Button
                        type="link"
                        className="edit"
                        onClick={() => showEdit(user)}
                      >
                        修改
                      </Button>
                      <Button
                        type="link"
                        className="del"
                        onClick={() => showDel(user)}
                      >
                        删除
                      </Button>
                    </>
                  )}
                </Space>
              )
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '用户名',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text.username}>
                {text.username}
              </Tooltip>
            ),
          },
          {
            title: '手机号',
            dataIndex: 'mobile_no',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              const user = record.user
              return (
                <Tooltip placement="leftTop" title={user.mobile_no}>
                  {user.mobile_no}
                </Tooltip>
              )
            },
          },
          {
            title: '姓名',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '昵称',
            dataIndex: 'nickname',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '邮箱',
            dataIndex: 'email',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '工号',
            dataIndex: 'employee_no',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '是否预置',
            dataIndex: 'is_preset',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render(is_preset) {
              return is_preset ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '部门',
            dataIndex: 'orgs',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (i, text, o) => {
              const t = text.user
              return (
                <Tooltip placement="leftTop" title={t.orgs_name}>
                  {t.orgs_name[0]}
                </Tooltip>
              )
            },
          },
          {
            title: '角色',
            dataIndex: 'roles',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (i, text, o) => {
              const t = text.user
              return (
                <Tooltip placement="leftTop" title={t.role_name}>
                  {t.role_name}
                </Tooltip>
              )
            },
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'user',
            width: 220,
            fixed: 'right',
            render(user) {
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      paramsRef.current.page = 1
                      watchUserInfo(user)
                      setUserInfos(user)
                    }}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => showReset(user)}
                  >
                    重置密码
                  </Button>
                  {!user.is_preset && (
                    <>
                      <Button
                        type="link"
                        className="edit"
                        onClick={() => showEdit(user)}
                      >
                        修改
                      </Button>
                      <Button
                        type="link"
                        className="del"
                        onClick={() => showDel(user)}
                      >
                        删除
                      </Button>
                    </>
                  )}
                </Space>
              )
            },
          },
        ]

  const watchCol = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 80,
      render(text, record, index) {
        return <span>{(paramsRef.current.page - 1) * 10 + index + 1}</span>
      },
    },
    {
      title: '角色',
      dataIndex: 'roles_name',
      width: '50%',
      ellipsis: {
        showTitle: false,
      },
      render: (i, text) => {
        return (
          <Tooltip placement="leftTop" title={text}>
            {text}
          </Tooltip>
        )
      },
    },
  ]
  const [batchId, setBatchId] = useState('')
  const batchDels = async (id) => {
    await dispatch(
      batchDel({
        co_user_id__in: id,
      })
    )
    message.success('批量删除成功')
    paramsRef.current.page = 1
    await dispatch(getUserList())
    setBatchUserDel(false)
    onReset()
  }
  // 多选框
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBatchId(`${selectedRowKeys}`)
    },
  }

  // 获取所有用户列表数据
  useEffect(() => {
    if (getSuperGroup() === 'true') {
      dispatch(getUserList())
    } else {
      dispatch(
        getUserList({
          co_id: getCompany(),
        })
      )
      const getOrgList = async () => {
        const res = await dispatch(
          getOrgTreeList(
            {
              co_id: getCompany(),
              is_page: true,
            },
            false
          )
        )
        setOrgData(res)
      }
      getOrgList()
    }
  }, [dispatch])

  // 数据回显
  useEffect(() => {
    const values = {
      ...userInfos,
      ...userInfos.user,
    }
    setTimeout(() => {
      userForm.current?.setFieldsValue({
        ...values,
        co_id: userInfos.co_id ? userInfos.co_id : userCoId ? userCoId : null,
      })
    }, 0)
  }, [userInfos, userCoId])

  // 点击新增用户弹出框
  const showAddVisible = () => {
    dispatch(
      getPermissionLists({
        co_id: userCoId ? userCoId : getCompany(),
        is_page: true,
      })
    )
    setUserInfos({})
    setTargetKeys([])
    setVisible(true)
  }
  const showEdit = (users) => {
    dispatch(
      getPermissionLists({
        co_id: users.co_id,
        is_page: true,
      })
    )
    dispatch(
      getOrgTreeList({
        co_id: users.co_id,
        is_page: true,
      })
    )
    users = JSON.parse(JSON.stringify(users))
    setUserInfos(users)
    const { user, ...rest } = { ...users }
    setUser(user)
    setTargetKeys(user.roles)
    setVisible(true)
  }

  // 查询用户
  const userParamsRef = useRef({
    page: 1,
    page_size: 10,
  })
  const seachUser = async (values) => {
    setUserCoId(values.co_id)
    setCompId(values.co_id)
    userParamsRef.current.co_id = values.co_id
    userParamsRef.current.org_id = orgIds ? orgIds : null
    userParamsRef.current.fuzzy_name = values.fuzzy_name
    userParamsRef.current.page = 1
    paramsRef.current.page = 1
    await dispatch(getUserList(userParamsRef.current))
  }

  // 查看用户
  const watchUserInfo = async ({ co_user_id, user, roles }) => {
    setUser(user)

    setWatchUser(true)
    await dispatch(getUserInfo(co_user_id))
  }
  // 新增用户
  const onFinish = async (values) => {
    if (userInfos.co_user_id) {
      await dispatch(
        editUser({
          ...values,
          user: {
            id: user.id,
            mobile_no: values.mobile_no,
            username: values.username,
            email: values.email,
            orgs: !orgId ? [] : [orgId],
            roles: values.roles,
          },
          emp_id: userInfos.co_user_id,
        })
      )
    } else {
      await dispatch(
        addUser({
          ...values,
          user: {
            id: values.id,
            mobile_no: values.mobile_no,
            username: values.username,
            email: values.email,
            orgs: !orgId ? [] : [orgId],
            roles: batchAdd || [],
          },
        })
      )
      setOrgId('')
      SetBatchAdd('')
      setTargetKeys('')
    }
    message.success(userInfos.co_user_id ? '修改用户成功' : '新增用户成功')
    setVisible(false)
    paramsRef.current.page = 1
    dispatch(getUserList({ co_id: userCoId ? userCoId : null }))
  }

  const showDel = ({ user, ...rest }) => {
    setUserInfos(rest)
    setUser(user)
    SetDelModal(true)
  }

  // 删除用户
  const delUser = async (id) => {
    await dispatch(delUsers(id))
    message.success('删除用户成功')
    paramsRef.current.page = 1
    dispatch(getUserList())
    SetDelModal(false)
    onReset()
  }

  // 分页处理
  const onChange = (page, pageSize) => {
    paramsRef.current.co_id = compUser
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getUserList(paramsRef.current))
  }

  const changeOrg = (value, node) => {
    setOrgId(node.org_id)
    setOrgIds(node.org_id)
  }

  const paginations = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: paramsRef.current.page,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }
  const filterOption = (inputValue, option) =>
    option.description.indexOf(inputValue) > -1

  const [compUser, setCompUser] = useState('')
  const changeComps = async (i, o) => {
    setCompUser(i)
    const res = await dispatch(
      getOrgTreeList(
        {
          co_id: i,
          is_page: true,
        },
        false
      )
    )
    form.resetFields(['org_id'])
    setOrgIds('')
    setOrgData(JSON.parse(JSON.stringify(res)))
  }
  const changeComp = (i, o) => {
    setCompId(i)
    dispatch(
      getPermissionLists(
        {
          co_id: i,
          is_page: true,
        },
        false
      )
    )
    dispatch(
      getOrgTreeList(
        {
          co_id: i,
          is_page: true,
        },
        false
      )
    )
  }

  const showReset = ({ user }) => {
    setResetModal(true)
    setUser(user)
  }

  const resetUserPassword = async (id) => {
    await dispatch(
      resetPassword({
        user: id,
      })
    )
    message.success('重置密码成功')
    setResetModal(false)
  }

  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    setCompUser('')
    setOrgIds('')
    setOrgData([])
    setUserCoId('')
  }
  return (
    <div className={styles.root}>
      <ConfigProvider locale={zhCH}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>系统管理</Breadcrumb.Item>
            <Breadcrumb.Item>用户管理</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="search-card">
            <Form
              autoComplete="off"
              form={form}
              validateTrigger={['onBlur', 'onChange']}
              className="form-search"
              onFinish={seachUser}
            >
              <div className="flex">
                {getSuperGroup() === 'true' ? (
                  <Form.Item label="企业/产品" name="co_id">
                    <Select
                      onChange={changeComps}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      placeholder="请选择企业/产品"
                      style={{ width: 238 }}
                    >
                      {compListAll.map((item) => (
                        <Select.Option value={item.co_id} key={item.co_id}>
                          {item.co_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  ''
                )}

                <Form.Item label="部门" name="org_id">
                  <TreeSelect
                    onSelect={changeOrg}
                    treeDefaultExpandAll
                    key="org_id"
                    style={{ width: 238 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={orgData}
                    placeholder="请选择部门"
                  ></TreeSelect>
                </Form.Item>

                <Form.Item label="用户名/手机号" name="fuzzy_name">
                  <Input
                    placeholder="请输入用户名或手机号"
                    style={{ width: 238 }}
                  ></Input>
                </Form.Item>
              </div>
              <Form.Item>
                <Space size={[24]}>
                  <MyButton type="default" htmlType="submit">
                    查询
                  </MyButton>
                  <MyButton onClick={onReset}>重置</MyButton>
                </Space>
              </Form.Item>
            </Form>
          </Card>
          <Space size={[24]} className="space-heigth">
            <MyButton type="default" onClick={showAddVisible}>
              + 新增用户
            </MyButton>
            <MyButton
              type="default"
              onClick={() => {
                if (!batchId) return message.error('请至少勾选一条数据')
                setBatchUserDel(true)
              }}
            >
              批量删除
            </MyButton>
          </Space>
          <div className="right_table" ref={tableRef}>
            <Table
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey={(i) => {
                return i.co_user_id
              }}
              rowSelection={{
                ...rowSelection,
                getCheckboxProps(value) {
                  return {
                    disabled: value.is_preset,
                  }
                },
              }}
              columns={columns}
              dataSource={userList}
              pagination={paginations}
            ></Table>
          </div>
        </div>
        {/* 新增用户 */}
        <Drawer
          width={624}
          destroyOnClose={true}
          size="large"
          maskClosable={false}
          title={userInfos.co_user_id ? '修改用户' : '新增用户'}
          placement="right"
          onClose={() => {
            setVisible(false)
          }}
          visible={visible}
        >
          <Form
            autoComplete="off"
            ref={userForm}
            validateTrigger={['onBlur', 'onChange']}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
          >
            <div className="form-btn">
              {getSuperGroup() === 'true' ? (
                <Form.Item
                  label="企业/产品"
                  name="co_id"
                  rules={rulesObj.current.co_id}
                >
                  <Select
                    disabled={userInfos.co_user_id ? true : false}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="请选择企业/产品"
                    onChange={changeComp}
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
              <div className="flex">
                <Form.Item
                  className="w-50"
                  label="用户名"
                  name="username"
                  rules={rulesObj.current.username}
                >
                  <Input
                    disabled={userInfos.co_user_id ? true : false}
                    maxLength={20}
                    placeholder="请输入用户名"
                  ></Input>
                </Form.Item>
                <Form.Item
                  className="w-50"
                  label="手机号"
                  name="mobile_no"
                  rules={rulesObj.current.mobile_no}
                >
                  <Input maxLength={11} placeholder="请输入手机号"></Input>
                </Form.Item>
              </div>

              <div className="flex">
                <Form.Item
                  className="w-50"
                  label="姓名"
                  name="full_name"
                  rules={rulesObj.current.full_name}
                >
                  <Input maxLength={20} placeholder="请输入姓名"></Input>
                </Form.Item>
                <Form.Item
                  className="w-50"
                  label="昵称"
                  name="nickname"
                  rules={rulesObj.current.nickname}
                >
                  <Input maxLength={20} placeholder="请输入昵称"></Input>
                </Form.Item>
              </div>
              <Form.Item
                label="邮箱"
                name="email"
                rules={rulesObj.current.email}
              >
                <Input maxLength={50} placeholder="请输入邮箱"></Input>
              </Form.Item>
              <div className="flex">
                <Form.Item
                  rules={rulesObj.current.employee_no}
                  className="w-50"
                  label="工号"
                  name="employee_no"
                >
                  <Input maxLength={20} placeholder="不超过20个字"></Input>
                </Form.Item>
                <Form.Item className="w-50" label="部门" name="orgs_name">
                  <TreeSelect
                    allowClear={true}
                    treeDefaultExpandAll
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={orgTreeListCope}
                    placeholder="请选择部门名称"
                    onSelect={changeOrg}
                  ></TreeSelect>
                </Form.Item>
              </div>

              <Form.Item label="所属角色" name="roles">
                <Transfer
                  showSelectAll={false}
                  titles={['未选角色', '已选角色']}
                  filterOption={filterOption}
                  dataSource={permissionListAll}
                  targetKeys={targetKeys}
                  onChange={handleChange}
                  render={(item) => {
                    return item.title
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex_end">
                <Space>
                  <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                  <MyButton type="default" htmlType="submit">
                    {userInfos.co_user_id ? '修改' : '新增'}
                  </MyButton>
                </Space>
              </div>
            </Form.Item>
          </Form>
        </Drawer>
        {/* 批量删除用户对话框 */}
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          centered
          onCancel={() => setBatchUserDel(false)}
          title="批量删除用户"
          visible={batchUserDel}
          getContainer={false}
          okText="删除"
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => setBatchUserDel(false)}>取消</MyButton>
              <MyButton type="default" onClick={() => batchDels(batchId)}>
                删除
              </MyButton>
            </Space>,
          ]}
        >
          <p>删除所选用户后将删除所选用户下所有角色</p>
          <p>确定要删除所选用户吗？</p>
        </Modal>
        {/* 查看用户对话框 */}
        <Drawer
          destroyOnClose={true}
          size="large"
          placement="right"
          title="查看用户"
          onClose={() => {
            setWatchUser(false)
          }}
          visible={watchUser}
        >
          <div className="msg">
            <span className="linear"></span> 基本信息
          </div>
          <div className="watch_marg">
            {getSuperGroup() === 'true' ? (
              <p>
                <span>企业/产品：</span>
                {userInfo.co_name}
              </p>
            ) : (
              ''
            )}
            <p>
              <span>用户名：</span>
              {user.username}
            </p>
            <p>
              <span>手机号：</span>
              {user.mobile_no}
            </p>
            <p>
              <span>姓名：</span>
              {userInfo.full_name}
            </p>
            <p>
              <span>昵称：</span>
              {userInfo.nickname}
            </p>
            <p>
              <span>邮箱：</span>
              {user.email}
            </p>
            <p>
              <span>工号：</span>
              {userInfo.employee_no}
            </p>
            <p>
              <span>部门：</span>
              {user.orgs_name}
            </p>
            <p>
              <span>所属角色：</span>
            </p>
          </div>

          <Table
            size="small"
            columns={watchCol}
            dataSource={user.roles_name}
          ></Table>
        </Drawer>
        {/* 删除用户 */}
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          centered
          onCancel={() => SetDelModal(false)}
          title="删除用户"
          visible={delModal}
          getContainer={false}
          okText="删除"
          cancelText="取消"
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => SetDelModal(false)}>取消</MyButton>
              <MyButton
                type="default"
                onClick={() => delUser(userInfos.co_user_id)}
              >
                删除
              </MyButton>
            </Space>,
          ]}
        >
          <p>确定要删除用户"{user.username}"吗？</p>
        </Modal>
        {/* 重置密码 */}
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          centered
          onCancel={() => setResetModal(false)}
          title="重置密码"
          visible={resetModal}
          getContainer={false}
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => setResetModal(false)}>取消</MyButton>
              <MyButton
                type="default"
                onClick={() => resetUserPassword(user.id)}
              >
                重置
              </MyButton>
            </Space>,
          ]}
        >
          <p>确定要重置用户“{user.username}”的密码吗？</p>
        </Modal>
      </ConfigProvider>
    </div>
  )
}
