import Cookie from 'js-cookie'

const Token = 'token'
const SuperGroup = 'super_group'
const Company = 'company'
const OaOrg = 'oaorg'
const TitleName = 'title_name'
const LogoUrl = 'logo_url'
/**
 * 存储token
 * @param {*} token
 */
export function setToken(token) {
  Cookie.set(
    Token,
    token,
    { expires: new Date().getTime() + 23 * 60 * 60 * 1000 },
    { HttpOnly: true }
  )
}
export function setSuperGroup(super_group) {
  Cookie.set(SuperGroup, super_group, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setCompany(company) {
  Cookie.set(Company, company, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setOaOrg(oaorg) {
  Cookie.set(OaOrg, oaorg, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setTitleName(title_name) {
  Cookie.set(TitleName, title_name, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setLogoUrl(logo_url) {
  Cookie.set(LogoUrl, logo_url, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}

/**
 * 获取token
 * @returns
 */
export function getToken() {
  return Cookie.get(Token)
}
export function getSuperGroup() {
  return Cookie.get(SuperGroup)
}
export function getCompany() {
  return Cookie.get(Company)
}
export function getOaOrg() {
  return Cookie.get(OaOrg)
}
export function getTitleName() {
  return Cookie.get(TitleName)
}
export function getLogoUrl() {
  return Cookie.get(LogoUrl)
}

/**
 * 移除token
 * @returns
 */
export function removeToken() {
  return Cookie.remove(Token)
}
export function removeSuperGroup() {
  return Cookie.remove(SuperGroup)
}
export function removeCompany() {
  return Cookie.remove(Company)
}
export function removeOaOrg() {
  return Cookie.remove(OaOrg)
}
export function removeTitleName() {
  return Cookie.remove(TitleName)
}
export function removeLogoUrl() {
  return Cookie.remove(LogoUrl)
}

/**
 * 判断是否有token
 * @returns
 */
export function hasToken() {
  return !!getToken()
}
