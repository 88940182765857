import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import LoginCas from './pages/Login/loginCas'
import Layout from './pages/Layout'
import PrivateRoute from './components/PrivateRoute'
import history from './utils/history'
import Login from './pages/Login'
import Help from './pages/Help'
const PrivacyAgreement = lazy(() => import('./pages/PrivacyAgreement'))
const ContactInformation = lazy(() => import('./pages/ContactInformation'))
const ServiceAgreement = lazy(() => import('./pages/ServiceAgreement'))

export default function App() {
  return (
    <Router history={history}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Redirect exact from="/" to="/home"></Redirect>
          <Route path="/loginHtml">{<Login></Login>}</Route>
          <Route path="/help/:id">{<Help></Help>}</Route>
          <Redirect exact from="/help" to="/help/10002.md"></Redirect>

          <Route path="/legal-agreement/tos.html">{<ServiceAgreement />}</Route>
          <Route path="/legal-agreement/privacy.html">
            {<PrivacyAgreement />}
          </Route>
          <Route path="/legal-agreement/about.html">
            {<ContactInformation />}
          </Route>
          <Route path="/login">{<LoginCas />}</Route>
          <PrivateRoute path="/home">{<Layout />}</PrivateRoute>
          <Redirect path="*" to="/home"></Redirect>
        </Switch>
      </Suspense>
    </Router>
  )
}
