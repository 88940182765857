import { useSize } from 'ahooks'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import {
  Breadcrumb,
  Card,
  Form,
  Input,
  Select,
  ConfigProvider,
  Space,
  Table,
  Tabs,
  DatePicker,
  Tooltip,
} from 'antd'
import moment from 'moment'
import MyButton from '../../../../components/MyButton'
import zhCH from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { useDispatch, useSelector } from 'react-redux'
import { getCompLists } from '../../../../store/actions/comp'
import {
  exportsLog,
  getLoginLog,
  getOptLog,
} from '../../../../store/actions/login'
import {
  getLoginStatus,
  getOptType,
  getOptTypeList,
} from '../../../../store/actions/dictionary'

const { TabPane } = Tabs
const { RangePicker } = DatePicker
export default function JournalManage() {
  const [coId, setCoId] = useState(getCompany())
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const tableRef = useRef(null)
  const size = useSize(tableRef)

  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  useEffect(() => {
    dispatch(getLoginLog())
  }, [dispatch])

  // useEffect(() => {
  //   dispatch(getOptLog())
  // }, [dispatch])

  useEffect(() => {
    dispatch(
      getOptType(
        {
          co_id: coId,
          dic_code: 'SYS_OPT_TYPE',
          is_page: true,
        },
        false
      )
    )
  }, [coId])
  useEffect(() => {
    dispatch(getOptTypeList())
  }, [])

  useEffect(() => {
    dispatch(
      getLoginStatus(
        {
          co_id: coId,
          dic_code: 'SYS_OPT_RESULT',
          is_page: true,
        },
        false
      )
    )
  }, [coId])

  const { compListAll } = useSelector((state) => state.comp)
  const { loginLog, loginTotal, optTotla, optLog } = useSelector(
    (state) => state.login
  )
  const { optStatusList, optTypeList, optTypeLists } = useSelector(
    (state) => state.dictionary
  )
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  // 登录日志
  const logColumns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '姓名',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录状态',
            dataIndex: 'login_status',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录方式',
            dataIndex: 'login_method',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: 'IP地址',
            dataIndex: 'ip_address',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作系统',
            dataIndex: 'opt_system',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '终端类型',
            dataIndex: 'terminal_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '浏览器版本',
            dataIndex: 'browser_version',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '姓名',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录状态',
            dataIndex: 'login_status',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '登录方式',
            dataIndex: 'login_method',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: 'IP地址',
            dataIndex: 'ip_address',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作系统',
            dataIndex: 'opt_system',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '终端类型',
            dataIndex: 'terminal_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '浏览器版本',
            dataIndex: 'browser_version',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
  // 操作日志
  const oprColumns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '姓名',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作结果',
            dataIndex: 'opt_result',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },

          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '用户名',
            dataIndex: 'user_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '姓名',
            dataIndex: 'full_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作类型',
            dataIndex: 'opt_type',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              return (
                <Tooltip placement="leftTop" title={text}>
                  {text}
                </Tooltip>
              )
            },
          },
          {
            title: '时间',
            dataIndex: 'opt_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作结果',
            dataIndex: 'opt_result',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },

          {
            title: '详细信息',
            dataIndex: 'detail_info',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
        ]
  const callback = (key) => {
    if (key === '1') {
      dispatch(getLoginLog())
    } else if (key === '2') {
      dispatch(getOptLog())
    }
    onReset()
    paramsRef.current.page = 1
  }

  const [form] = Form.useForm()
  const [optForm] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    optForm.resetFields()
  }
  // 多选框
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows)
    },
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: loginTotal,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (loginTotal) => {
      return `共${loginTotal}条`
    },
  }
  const pagination = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: optTotla,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (optTotla) => {
      return `共${optTotla}条`
    },
  }

  const changeLog = (page, pageSize) => {
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getLoginLog(paramsRef.current))
  }
  const changeOpt = (page, pageSize) => {
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getOptLog(paramsRef.current))
  }
  const searchOpt = async (values) => {
    paramsRef.current = { ...values }
    paramsRef.current.co_id = values.co_id
    paramsRef.current.opt_time__gte = values.time
      ? moment(values.time[0]._d).format('YYYY-MM-DD') + ' ' + '00:00:00'
      : null
    paramsRef.current.opt_time__lte = values.time
      ? moment(values.time[0]._d).format('YYYY-MM-DD') + ' ' + '23:59:59'
      : null
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
    await dispatch(
      getOptLog({
        ...values,
        ...paramsRef.current,
      })
    )
  }

  const exportLog = async (type) => {
    let params = {}
    params.co_id = coId
    params.log_type = type
    params = { ...paramsRef.current, ...params }
    const res = await dispatch(exportsLog(params, false))
    const filename = decodeURI(
      res.headers['content-disposition'].split(';')[1].split('filename=')[1]
    )
    const blob = new Blob([res.data])
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
    setLoading(false)
  }
  console.log(paramsRef.current)

  return (
    <ConfigProvider locale={zhCH}>
      <div className={styles.root}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>系统管理</Breadcrumb.Item>
            <Breadcrumb.Item>日志管理</Breadcrumb.Item>
          </Breadcrumb>

          <Card style={{ height: '100%' }}>
            <Tabs
              style={{ height: '100%' }}
              defaultActiveKey="1"
              onChange={callback}
            >
              <TabPane tab="登录日志" key="1" style={{ height: '100%' }}>
                <div className="log-search-card">
                  <Form
                    form={form}
                    autoComplete="off"
                    // className="log-search"
                    onFinish={async (values) => {
                      paramsRef.current = { ...values }
                      paramsRef.current.co_id = values.co_id
                      paramsRef.current.opt_time__gte = values.time
                        ? moment(values.time[0]._d).format('YYYY-MM-DD') +
                          ' ' +
                          '00:00:00'
                        : null
                      paramsRef.current.opt_time__lte = values.time
                        ? moment(values.time[0]._d).format('YYYY-MM-DD') +
                          ' ' +
                          '23:59:59'
                        : null
                      paramsRef.current.page = 1
                      paramsRef.current.page_size = 10
                      delete paramsRef.current.time
                      delete values.time
                      await dispatch(
                        getLoginLog({
                          ...values,
                          ...paramsRef.current,
                        })
                      )
                    }}
                  >
                    <div className="flex">
                      {getSuperGroup() === 'true' ? (
                        <Form.Item label="企业/产品" name="co_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="请选择企业/产品"
                            style={{ width: 238 }}
                            onChange={(value) => {
                              setCoId(value)
                            }}
                          >
                            {compListAll.map((item) => (
                              <Select.Option
                                value={item.co_id}
                                key={item.co_id}
                              >
                                {item.co_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <Form.Item label="用户名" name="user_name">
                        <Input
                          placeholder="请输入用户名"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>
                      <Form.Item label="姓名" name="full_name">
                        <Input
                          placeholder="请输入姓名"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>
                      <Form.Item label="ip地址" name="ip_address">
                        <Input
                          placeholder="请输入ip地址"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>
                      <Form.Item label="操作类型" name="opt_type_code">
                        <Select
                          placeholder="请选择操作类型"
                          style={{ width: 238 }}
                        >
                          {optTypeList
                            .filter(
                              (_) =>
                                _.dic_item_code === 'LOGIN' ||
                                _.dic_item_code === 'LOGOUT'
                            )
                            .map((item) => {
                              return (
                                <Select.Option
                                  value={item.dic_item_code}
                                  key={item.dic_item_id}
                                >
                                  {item.dic_item_name}
                                </Select.Option>
                              )
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="flex">
                      <Form.Item label="登录状态" name="login_status_code">
                        <Select
                          placeholder="请选择登录状态"
                          style={{ width: 238 }}
                        >
                          {optStatusList.map((item) => {
                            return (
                              <Select.Option
                                value={item.dic_item_code}
                                key={item.dic_item_id}
                              >
                                {item.dic_item_name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="时间" name="time">
                        <RangePicker
                          locale={locale}
                          // showTime
                          style={{ width: 260 }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Space size={[20]}>
                          <MyButton type="default" htmlType="submit">
                            查询
                          </MyButton>
                          <MyButton onClick={onReset}>重置</MyButton>
                        </Space>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
                <Space size={[24]} className="space-heigth">
                  <MyButton
                    loading={loading}
                    type="default"
                    onClick={() => {
                      setLoading(true)
                      exportLog('login')
                    }}
                  >
                    导出
                  </MyButton>
                </Space>
                <div className="log_table" ref={tableRef}>
                  <Table
                    // scroll={{ y: size?.height - 100 + 'px' }}
                    size="small"
                    onChange={changeLog}
                    rowKey="log_id"
                    // rowSelection={{ ...rowSelection }}
                    columns={logColumns}
                    dataSource={loginLog}
                    showCount
                    pagination={paginations}
                  ></Table>
                </div>
              </TabPane>
              <TabPane tab="操作日志" key="2">
                <div className="log-search-card">
                  <Form
                    form={optForm}
                    autoComplete="off"
                    // className="log-search"
                    onFinish={searchOpt}
                  >
                    <div className="flex">
                      {getSuperGroup() === 'true' ? (
                        <Form.Item label="企业/产品" name="co_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="请选择企业/产品"
                            style={{ width: 238 }}
                            onChange={(value) => {
                              setCoId(value)
                            }}
                          >
                            {compListAll.map((item) => (
                              <Select.Option
                                value={item.co_id}
                                key={item.co_id}
                              >
                                {item.co_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <Form.Item label="用户名" name="user_name">
                        <Input
                          placeholder="请输入用户名"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>
                      <Form.Item label="姓名" name="full_name">
                        <Input
                          placeholder="请输入姓名"
                          style={{ width: 238 }}
                        ></Input>
                      </Form.Item>

                      <Form.Item label="操作类型" name="opt_type_code">
                        <Select
                          placeholder="请选择操作类型"
                          style={{ width: 238 }}
                        >
                          {optTypeList
                            .filter(
                              (_) =>
                                _.dic_item_code !== 'LOGIN' &&
                                _.dic_item_code !== 'LOGOUT'
                            )
                            .map((item) => {
                              return (
                                <Select.Option
                                  value={item.dic_item_code}
                                  key={item.dic_item_id}
                                >
                                  {item.dic_item_name}
                                </Select.Option>
                              )
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="操作结果" name="opt_result_code">
                        <Select
                          placeholder="请选择操作结果"
                          style={{ width: 238 }}
                        >
                          {optStatusList.map((item) => {
                            return (
                              <Select.Option
                                value={item.dic_item_code}
                                key={item.dic_item_id}
                              >
                                {item.dic_item_name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="flex">
                      <Form.Item label="时间" name="time">
                        <RangePicker
                          locale={locale}
                          // showTime
                          style={{ width: 260 }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Space size={[20]}>
                          <MyButton type="default" htmlType="submit">
                            查询
                          </MyButton>
                          <MyButton onClick={onReset}>重置</MyButton>
                        </Space>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
                <Space size={[24]} className="space-heigth">
                  <MyButton
                    loading={loading}
                    type="default"
                    onClick={() => {
                      setLoading(true)
                      exportLog('operation')
                    }}
                  >
                    导出
                  </MyButton>
                </Space>
                <div className="log_table" ref={tableRef}>
                  <Table
                    // scroll={{ y: size?.height - 100 + 'px' }}
                    size="small"
                    onChange={changeOpt}
                    rowKey="opt_id"
                    // rowSelection={{ ...rowSelection }}
                    columns={oprColumns}
                    dataSource={optLog}
                    showCount
                    pagination={pagination}
                  ></Table>
                </div>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    </ConfigProvider>
  )
}
