import request from '../../utils/request'
import {
  GETCASORGSLIST,
  GETCASSUBSETORGSLIST,
  GETORGALLLIST,
  GETORGLIST,
  GETORGTREELIST,
} from '../constants'
/**
 * 获取树形数据
 * @returns
 */
export const getOrgTreeList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/org/tree/', {
      params,
      isLoading,
    })
    const init = (arr) => {
      arr = arr.map((item) => {
        if (item.children) {
          item.children = init(item.children)
        }
        item.title = item.org_name || '全部'
        item.value = item.org_code || '全部'
        item.key = item.org_id || '全部'
        return item
      })
      return arr
    }
    let list = [res.data.data]
    let arr = init(list)
    dispatch({
      type: GETORGTREELIST,
      payload: arr,
    })
    return res.data.data.children
  }
}

/**
 * 获取列表数据
 * @param {*} params
 * @returns
 */
export const getOrgList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/org/', {
      params,
    })
    dispatch({
      type: GETORGLIST,
      payload: res.data.data,
    })
  }
}
export const getOrgAllList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/org/', {
      params,
    })
    dispatch({
      type: GETORGALLLIST,
      payload: res.data.data,
    })
  }
}

/**
 * 新增部门
 * @param {*} values
 * @returns
 */
export const addOrg = (values) => {
  return async (dispatch) => {
    await request.post('sys/org/', values)
  }
}

/**
 * 修改部门
 * @param {*} values
 * @returns
 */
export const editOrgs = (values) => {
  return async (dispatch) => {
    await request.put(`sys/org/${values.id}/`, values)
  }
}

/**
 * 删除部门
 * @param {*} id
 * @returns
 */
export const delOrgs = (id) => {
  return async (dispatch) => {
    await request.delete(`sys/org/${id}/`)
  }
}

/**
 * 移动部门
 * @param {*} values
 * @returns
 */
export const moveOrgs = (values) => {
  return async (dispatch) => {
    await request.put('sys/org/move/', values)
  }
}

/**
 * cas组织列表
 * @returns
 */
export const getCasOrgsList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/org/cas/', {
      params,
      isLoading,
    })
    // console.log(res.data.data)
    dispatch({
      type: GETCASORGSLIST,
      payload: res.data.data,
    })
    // const arr = res.
  }
}

/**
 * cas子组织列表
 * @returns
 */
export const getCasSubsetOrgsList = (params, isLoading) => {
  const obj = {}
  obj.org_code = params.org_code
  // const {org_code} = params
  console.log(obj)
  return async (dispatch) => {
    const res = await request.get('sys/org/cas/subset/', { params, isLoading })
    console.log(res.data.data, params)
    const init = (arr) => {
      arr = arr.map((item) => {
        if (item.children) {
          item.children = init(item.children)
        }
        item.key = item.org_code + item.org_name
        item.parent_id = params.id
        item.full_name = item.org_name + '/' + params.full_name
        item.children = []
        return item
      })

      return arr
    }
    let arr = init(res.data.data)
    console.log(arr)
    return arr
  }
}

/**
 * 导入部门
 * @param {*} values
 * @param {*} isLoading
 * @returns
 */
export const importCasOrgs = (values) => {
  return async (dispatch) => {
    return await request.post('sys/org/cas/import/', values)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/org/check/', {
      params,
      isLoading,
    })
  }
}
