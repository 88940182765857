import React, { useRef } from 'react'
import {
  Layout,
  Breadcrumb,
  Card,
  Form,
  Input,
  Button,
  Space,
  Table,
  Drawer,
  TreeSelect,
  message,
  Modal,
  ConfigProvider,
  Select,
  Tooltip,
} from 'antd'
import styles from './index.module.scss'
import { Tree } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import {
  addFucs,
  delFucs,
  editFucs,
  getFucList,
  getFucTreeList,
  getTrigger,
  moveFucs,
} from '../../../../store/actions/fuc'
import { useState } from 'react'
import zhCH from 'antd/lib/locale/zh_CN'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { getCompLists } from '../../../../store/actions/comp'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
const { Sider, Content } = Layout
export default function FucManage() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [lastFuc, setLastFuc] = useState('')
  const [fucInfo, setFucInfo, getFucInfo] = useGetState({})
  const [delModal, setDelModal] = useState(false)
  const [moveModal, setMoveModal] = useState(false)
  const [compId, setCompId, getCompId] = useGetState('')

  const { fucTreeList } = useSelector((state) => state.fuc)
  const { fucAllList, total, fucList } = useSelector((state) => state.fuc)
  const { compListAll } = useSelector((state) => state.comp)

  const formRef = useRef()
  const tableRef = useRef(null)
  const size = useSize(tableRef)
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
    isSearch: false,
  })

  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '请选择企业/产品',
      },
    ],
    func_code: [
      {
        required: true,
        message: '功能编号不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getFucInfo().func_id ? getFucInfo().func_id : null,
                co_id: getFucInfo().co_id ? getFucInfo().co_id : null,

                field: 'code',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('功能编号已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    func_name: [
      {
        required: true,
        message: '功能名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    func_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })

  const addRef = useRef()
  // 树形数据
  useEffect(() => {
    dispatch(
      getFucTreeList({
        co_id: getCompany(),
      })
    )
    getFucComment()
  }, [])

  useEffect(() => {
    if (compListAll.length > 0) {
      setCompId(getCompany())
    }
  }, [compListAll])

  //获取企业/产品列表
  useEffect(() => {
    getSuperGroup() === 'true' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  // 数据回显
  useEffect(() => {
    const values = { ...fucInfo }
    setTimeout(() => {
      addRef.current?.setFieldsValue({
        ...values,
        parent: values.parent_name,
        parent_name: leftId === undefined ? '全部' : leftId,
        co_id: fucInfo.co_id,
      })
    }, 0)
  }, [fucInfo])

  // 表格数据
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])
  const columns =
    getSuperGroup() === 'true'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>{(paramsRef.current.page - 1) * 10 + index + 1}</span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'user',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => (
              <Tooltip placement="leftTop" title={record.co_name}>
                {record.co_name}
              </Tooltip>
            ),
          },
          {
            title: '功能编号',
            dataIndex: 'func_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '功能名称',
            dataIndex: 'func_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '上级功能',
            dataIndex: 'parent_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '功能描述',
            dataIndex: 'func_desc',
            width: 120,

            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,

            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,

            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'fucInfo',
            width: 240,
            fixed: 'right',
            render: (fuc, index, i) => {
              // console.log(fuc)
              if (!fuc.is_preset) {
                return (
                  <Space>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => editFuc(fuc)}
                    >
                      修改
                    </Button>

                    <Button
                      onClick={() => {
                        setMoveModal(true)
                        setFucInfo(fuc)
                        setMoveValue(null)
                        setLastFuc('')
                      }}
                      type="link"
                      disabled={
                        fucAllList.length === i || paramsRef.current.isSearch
                      }
                      className={{
                        edit:
                          fucAllList.length === i ||
                          !paramsRef.current.isSearch,
                      }}
                    >
                      移动
                    </Button>
                    <Button
                      onClick={() => {
                        changeUp(fuc.func_id, fuc.parent)
                      }}
                      type="link"
                      disabled={
                        i === 0 ||
                        paramsRef.current.isSearch ||
                        i === fucAllList.filter((item) => item.is_preset).length
                      }
                      className={{
                        edit:
                          i !== 0 &&
                          !paramsRef.current.isSearch &&
                          i !==
                            fucAllList.filter((item) => item.is_preset).length,
                      }}
                    >
                      上移
                    </Button>
                    <Button
                      onClick={() => {
                        changeDown(fuc.func_id, fuc.parent)
                      }}
                      type="link"
                      disabled={
                        fucAllList.length - 1 === i ||
                        paramsRef.current.isSearch
                      }
                      className={{
                        edit:
                          fucAllList.length - 1 !== i &&
                          !paramsRef.current.isSearch,
                      }}
                    >
                      下移
                    </Button>
                    <Button
                      type="link"
                      className="del"
                      onClick={() => showDelModal(fuc)}
                    >
                      删除
                    </Button>
                  </Space>
                )
              }
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>{(paramsRef.current.page - 1) * 10 + index + 1}</span>
              )
            },
          },

          {
            title: '功能编号',
            dataIndex: 'func_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '功能名称',
            dataIndex: 'func_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '上级功能',
            dataIndex: 'parent_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '功能描述',
            dataIndex: 'func_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },

          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'fucInfo',
            width: 240,
            fixed: 'right',
            render: (fuc, index, i) => {
              // console.log(fuc)
              if (!fuc.is_preset) {
                return (
                  <Space>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => editFuc(fuc)}
                    >
                      修改
                    </Button>

                    <Button
                      onClick={() => {
                        setMoveModal(true)
                        setFucInfo(fuc)
                        setMoveValue(null)
                        setLastFuc('')
                      }}
                      type="link"
                      disabled={
                        fucAllList.length === i || paramsRef.current.isSearch
                      }
                      className={{
                        edit:
                          fucAllList.length === i ||
                          !paramsRef.current.isSearch,
                      }}
                    >
                      移动
                    </Button>
                    <Button
                      onClick={() => {
                        changeUp(fuc.func_id, fuc.parent)
                      }}
                      type="link"
                      disabled={
                        i === 0 ||
                        paramsRef.current.isSearch ||
                        i === fucAllList.filter((item) => item.is_preset).length
                      }
                      className={{
                        edit:
                          i !== 0 &&
                          !paramsRef.current.isSearch &&
                          i !==
                            fucAllList.filter((item) => item.is_preset).length,
                      }}
                    >
                      上移
                    </Button>
                    <Button
                      onClick={() => {
                        changeDown(fuc.func_id, fuc.parent)
                      }}
                      type="link"
                      disabled={
                        fucAllList.length - 1 === i ||
                        paramsRef.current.isSearch
                      }
                      className={{
                        edit:
                          fucAllList.length - 1 !== i &&
                          !paramsRef.current.isSearch,
                      }}
                    >
                      下移
                    </Button>
                    <Button
                      type="link"
                      className="del"
                      onClick={() => showDelModal(fuc)}
                    >
                      删除
                    </Button>
                  </Space>
                )
              }
            },
          },
        ]

  const getFucComment = (search) => {
    let params = {}
    if (!paramsRef.current.isSearch) {
      params.co_id = compId
      params.parent = paramsRef.current.func_id
      params.top = params.parent ? null : true
    } else {
      params.fuzzy_name = formRef.current.getFieldValue('fuzzy_name')
    }
    params.is_page = true
    params.page = paramsRef.current.page
    params.pageSize = paramsRef.current.pageSize
    dispatch(getFucList(params))
  }
  const [count, setCount] = useState(null)
  // 左侧树形数据右侧渲染
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info)
    setLeftId(info?.selected ? info.node.func_name : '')
    setCount(info.node.children.length)
    setLastFuc(selectedKeys[0] === '全部' ? '' : selectedKeys[0])
    paramsRef.current = info.node
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
    paramsRef.current.isSearch = false
    getFucComment()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys)
    },
  }

  const [leftId, setLeftId] = useState('')
  // 新增弹窗
  const showVisible = () => {
    console.log('新增')
    let fuc = {
      co_id: compId,
      parent_name: leftId === undefined ? '全部' : leftId,
    }
    setFucInfo(fuc)
    setVisible(true)
  }
  // 修改弹窗
  const editFuc = (fuc) => {
    console.log('修改功能', fuc)
    fuc = JSON.parse(JSON.stringify(fuc))
    setFucInfo(fuc)
    setVisible(true)
  }
  // 删除弹窗
  const showDelModal = (fuc) => {
    setFucInfo(fuc)
    setDelModal(true)
  }
  // 新增功能
  const onFinish = async (values) => {
    if (fucInfo.func_id) {
      values.parent = fucInfo.parent
      await dispatch(editFucs({ ...values, id: fucInfo.func_id }))
      setFucInfo({})
    } else {
      values.parent = lastFuc
      // setLastFuc('')
      await dispatch(addFucs(values))
    }
    message.success(fucInfo.func_id ? '修改功能成功' : '新增功能成功')
    paramsRef.current.page = 1
    setVisible(false)
    getFucComment()
    await dispatch(
      getFucTreeList({
        co_id: compId,
        top: true,
      })
    )
  }

  const [moveValue, setMoveValue] = useState(null)
  const changeFuc = (value, node) => {
    console.log(value, node)
    setMoveValue(value)
    setLastFuc(node.func_id)
  }

  const search = async (values) => {
    console.log(values)
    paramsRef.current.isSearch = true
    paramsRef.current.page = 1
    await getFucComment()
  }

  // 分页处理
  const onChange = (page, pageSize) => {
    console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    getFucComment()
  }

  // 删除功能
  const delFuc = async (id) => {
    // console.log(id)
    await dispatch(delFucs(id))
    message.success('删除功能成功')
    paramsRef.current.page = 1
    setDelModal(false)
    getFucComment()
    dispatch(getFucTreeList({ co_id: compId, top: true }))
  }

  //上移
  const changeUp = async (id, parent) => {
    console.log('上移', id, parent)
    await dispatch(
      moveFucs({
        pk: id,
        action: 'up',
        parent: parent,
      })
    )
    await dispatch(
      getFucTreeList({
        co_id: funcId,
        top: true,
      })
    )
    message.success('上移成功')
    getFucComment()
  }
  // 下移
  const changeDown = async (id, parent) => {
    console.log('下移', id, parent)
    await dispatch(
      moveFucs({
        pk: id,
        action: 'down',
        parent: parent,
      })
    )
    await dispatch(
      getFucTreeList({
        co_id: funcId,
        top: true,
      })
    )
    message.success('下移成功')
    getFucComment()
  }

  // 移动弹窗
  const changeMove = async (params) => {
    if (!moveValue) {
      return message.error('请选择功能')
    }
    console.log(params)
    await dispatch(
      moveFucs({
        pk: params.func_id,
        action: 'move',
        parent: lastFuc ? lastFuc : null,
      })
    )
    message.success('移动成功')
    setMoveModal(false)
    getFucComment()
    dispatch(
      getFucTreeList({
        co_id: compId,
        top: true,
      })
    )
  }

  const paginations = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: paramsRef.current.page,
    total: total,
    // showSizeChanger: true,
    // pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }

  const onSearch = (val) => {
    console.log('123', val)
  }
  const [funcId, setFuncId] = useState(getCompany())

  const change = async (value) => {
    setCompId(value)
    setFuncId(value)
    console.log(`selected ${value}`)
    await dispatch(
      getFucTreeList({
        co_id: value,
        top: true,
        is_page: true,
      })
    )
    await dispatch(
      getFucList({
        co_id: value,
        top: true,
        is_page: true,
      })
    )
    setLeftId('')
    setLastFuc('')
  }

  const changeFuncs = async (value) => {
    console.log(value)
    setCompId(value)
    await dispatch(
      getFucTreeList({
        co_id: value,
        page: 1,
        top: true,
      })
    )
    await dispatch(
      getFucList({
        co_id: value,
        top: true,
      })
    )
  }
  const searchFuncs = (val) => {
    console.log('123', val)
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>功能管理</Breadcrumb.Item>
        </Breadcrumb>
        <Layout className="layout-contanior">
          <Card className="scroll">
            <Sider>
              {getSuperGroup() === 'true' && (
                <div label="企业/产品" name="co_id" className="seachCo">
                  <div style={{ width: 80 }}>企业/产品</div>
                  <Select
                    value={compId}
                    placeholder="请选择"
                    style={{ width: 'calc(100% - 40px)' }}
                    showSearch
                    placeholder="请选择企业/产品"
                    optionFilterProp="children"
                    onChange={change}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              <Tree onSelect={onSelect} treeData={fucTreeList} />
            </Sider>
          </Card>
          <Layout className="site-layout-background">
            <Content>
              <div className="content_card">
                <Card className="content_header_card">
                  <Form
                    autoComplete="off"
                    className="comment-seach"
                    ref={formRef}
                    onFinish={search}
                  >
                    <Form.Item
                      label="功能"
                      className="comment-input"
                      name="fuzzy_name"
                    >
                      <Input placeholder="请输入功能编号或者名称"></Input>
                    </Form.Item>
                    <Form.Item>
                      <Space size={[24]}>
                        <MyButton type="default" htmlType="submit">
                          查询
                        </MyButton>
                        <MyButton htmlType="reset">重置</MyButton>
                      </Space>
                    </Form.Item>
                  </Form>
                </Card>
                <div className="add-btn">
                  <MyButton type="default" onClick={showVisible}>
                    新增
                  </MyButton>
                </div>
                <div className="table_hei" ref={tableRef}>
                  <ConfigProvider locale={zhCH}>
                    <Table
                      scroll={{ y: size?.height - 95 + 'px' }}
                      size="small"
                      onChange={onChange}
                      rowKey={(i) => {
                        return i.func_id
                      }}
                      rowSelection={{ ...rowSelection }}
                      columns={columns}
                      dataSource={fucAllList}
                      pagination={
                        paramsRef.current.isSearch ? paginations : false
                      }
                    />
                  </ConfigProvider>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={fucInfo.func_id ? '修改功能' : '新增功能'}
        placement="right"
        onClose={() => {
          setVisible(false)
          setFucInfo({})
        }}
        visible={visible}
      >
        <Form
          autoComplete="off"
          ref={addRef}
          onFinish={onFinish}
          validateTrigger={['onBlur', 'onChange']}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
        >
          <div className="form-btn">
            <div className="flex">
              {getSuperGroup() === 'true' ? (
                <Form.Item
                  label="企业/产品"
                  name="co_id"
                  className="w-50"
                  rules={rulesObj.current.co_id}
                >
                  <Select
                    disabled
                    onChange={changeFuncs}
                    onSearch={searchFuncs}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="请选择企业/产品"
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              <Form.Item
                className="w-50"
                rules={rulesObj.current.func_code}
                label="功能编号"
                name="func_code"
              >
                <Input maxLength={50} placeholder="不超过50个字"></Input>
              </Form.Item>
            </div>
            <Form.Item
              rules={rulesObj.current.func_name}
              label="功能名称"
              name="func_name"
            >
              <Input maxLength={50} placeholder="不超过50个字"></Input>
            </Form.Item>
            <Form.Item
              label="功能描述"
              name="func_desc"
              rules={rulesObj.current.func_desc}
            >
              <Input.TextArea
                style={{ height: '100px' }}
                maxLength={500}
                placeholder="不超过500个字"
              ></Input.TextArea>
            </Form.Item>
            <Form.Item label="上级功能" name="parent_name">
              <TreeSelect
                disabled
                treeDefaultExpandAll
                key="co_id"
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={fucTreeList}
                placeholder="请选择功能名称"
                onSelect={changeFuc}
              ></TreeSelect>
            </Form.Item>
          </div>
          <Form.Item>
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton type="default" htmlType="submit">
                  {fucInfo.func_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      {/* 删除功能 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelModal(false)}
        title="删除功能"
        visible={delModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => delFuc(fucInfo.func_id)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>确定要删除功能“{fucInfo.func_name}”及其下级功能吗？</p>
      </Modal>
      {/* 移动弹窗 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setMoveModal(false)}
        title="移动功能"
        visible={moveModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setMoveModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => changeMove(fucInfo)}>
              确定
            </MyButton>
          </Space>,
        ]}
      >
        移动到：
        <TreeSelect
          style={{ width: 238 }}
          treeDefaultExpandAll
          key="co_id"
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={fucTreeList}
          placeholder="请选择功能"
          onSelect={changeFuc}
        ></TreeSelect>
      </Modal>
    </div>
  )
}
