import React, { useRef } from 'react'
import {
  Layout,
  Menu,
  Form,
  Popconfirm,
  message,
  Input,
  Modal,
  Button,
  Dropdown,
  Popover,
  Drawer,
} from 'antd'
import styles from './index.module.scss'
import photo from '../../assets/u3.png'
import logo from '../../assets/u27.png'
import {
  useLocation,
  Switch,
  Route,
  Link,
  useHistory,
  Router,
} from 'react-router-dom'
import { createFromIconfontCN } from '@ant-design/icons'
import Home from './Home'
import IntellectWrite from './IntellectWrite'
import { useDispatch, useSelector } from 'react-redux'
import {
  changePassword,
  getMenus,
  getUserInfo,
  logout,
} from '../../store/actions/login'
import CompManage from './SysManage/CompManage'
import routes from '../../routers/index'
import Usermanage from './SysManage/UserManage'
import { useState } from 'react'
import routers, { MyIcon } from '../../routers/index'
import PrivateRoute from '../../components/PrivateRoute'
import OrgManage from './SysManage/OrgManage'
import MenuManage from './SysManage/MenuManage'
import FucManage from './SysManage/FucManage'
import RoleManage from './SysManage/RoleManage'
import ConfigManage from './SysManage/ConfigManage'
import Help from '../Help'
import ServiceAgreement from '../ServiceAgreement'
import PrivacyAgreement from '../PrivacyAgreement'
import ContactInformation from '../ContactInformation'
import { useEffect } from 'react'
import {
  getCompany,
  getLogoUrl,
  getTitleName,
  removeCompany,
  removeOaOrg,
  removeSuperGroup,
  removeToken,
} from '../../utils/cookie'
import DataDictionary from './SysManage/DataDictionary'
import request from '../../utils/request'
import ProductConfgTemp from './SysManage/ProductConfgTemp'
import JournalManage from './SysManage/JournalManage'
import UpdateLogManage from './SysManage/UpdateLogManage'
import MyButton from '../../components/MyButton'
import { getUpdateLogList } from '../../store/actions/updates'
import Nofunc from '../Nofunc'
const { Header, Content, Sider } = Layout

export default function MyLayout() {
  const [isModal, setIsModal] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [ispopover, setIspopover] = useState(false)
  const [helpPopover, setHelpPopover] = useState(false)
  const [watch, setWatch] = useState(false)
  const { menus } = useSelector((state) => state.login)
  const { total, updatelogList } = useSelector((state) => state.updates)
  const location = useLocation()
  let pathname = location.pathname
  const dispatch = useDispatch()
  const history = useHistory()
  const changeForm = useRef({})
  const [form] = Form.useForm()
  useEffect(() => {
    dispatch(
      getMenus({
        co_id: getCompany(),
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserInfo())
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('firstLogin')) {
      localStorage.removeItem('firstLogin')
      menus.map((item) => {
        if (item.children) {
          history.push(item.children[0]?.menu_url)
        } else {
          history.push(item.menu_url)
        }
      })
    }
  }, [menus])

  const userInfo = useSelector((state) => state.login.userInfo)
  const { user } = userInfo

  const confirm = () => {
    let redirect = ''
    request
      .post('sys/logout/', {
        redirect: window.location.origin + window.location.pathname,
      })
      .then((res) => {
        if (res.data.data.status === 'redirect') {
          if (res.data.data.logout_url) {
            window.location.href = res.data.data.logout_url
          } else {
            window.location.reload()
          }
          removeToken()
          removeSuperGroup()
          removeCompany()
          removeOaOrg()
        }
      })
    message.success('退出成功')
  }

  const showModal = (e) => {
    e.stopPropagation()
    setIsModal(true)
    setIspopover(false)
  }

  const changePass = async () => {
    const values = form.getFieldsValue()
    const result = await dispatch(changePassword(values))
    if (result.data.data.code === 0) {
      setIsModal(false)
      message.success(result.data.data.msg)
      setTimeout(() => {
        dispatch(logout())
        history.replace('/home')
      }, 500)
    }
  }
  const to = (item) => {
    if (item.menu_url && item.open_mode === 'CURRENT_PAGE') {
      history.push(item.menu_url)
      if (item.menu_url === pathname) {
        history.push(item.menu_url)
      }
    } else if (item.menu_url && item.open_mode === 'NEW_PAGE') {
      window.open(item.menu_url)
    } else if (item.menu_url === pathname) {
    }
  }
  const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_3250468_t51dp1wy2j.js',
  })

  const initMenu = (list, isChildren) => {
    console.log(list, 'list---->')
    list = list?.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu
            // icon={item.icon}
            icon={
              isChildren ? null : item.icon ? (
                <img
                  alt=""
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  src={item.icon}
                />
              ) : (
                <MyIcon type="icon-zhinengzhuanxie18x18" />
              )
            }
            title={item.menu_name}
            key={item.menu_url || item.menu_code}
          >
            {initMenu(item.children, true)}
          </Menu.SubMenu>
        )
      }

      return (
        <Menu.Item
          key={item.menu_url || item.menu_code}
          icon={
            isChildren ? null : item.icon ? (
              <img
                alt=""
                style={{
                  width: 16,
                  height: 16,
                }}
                src={item.icon}
              />
            ) : (
              <MyIcon type="icon-zhinengzhuanxie18x18" />
            )
          }
        >
          <a onClick={() => to(item)} title={item.menu_name}>
            {item.menu_name}
          </a>
        </Menu.Item>
      )
    })
    return list
  }
  return (
    <div className={styles.root}>
      <Layout className="my-layout">
        {location.pathname !== '/home/nofunc' && (
          <Sider
            trigger={null}
            width={216}
            style={{
              background: 'linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)',
            }}
            className="site-layout-background"
            collapsible
            collapsed={collapsed}
          >
            <div className="logo">
              {!collapsed && (
                <img
                  src={getLogoUrl() !== 'undefined' ? getLogoUrl() : logo}
                  alt=""
                />
              )}
              <span>
                {collapsed ? (
                  <MyIcon
                    className="anticon-menu-fold"
                    type="icon-toggle-right1"
                    onClick={() => setCollapsed(!collapsed)}
                  ></MyIcon>
                ) : (
                  <MyIcon
                    className="anticon-menu-unfold "
                    type="icon-toggle-left2"
                    onClick={() => setCollapsed(!collapsed)}
                  ></MyIcon>
                )}
              </span>
            </div>
            <Menu
              mode="inline"
              style={{ height: '100%', borderRight: 0 }}
              selectedKeys={[pathname]}
              defaultOpenKeys={['/home/sysmanage']}
            >
              {/* 遍历路由 */}
              {initMenu(
                menus
                  .filter((item) => item.menu_code === 'SYS_MANAGE')
                  .reduce((acc, obj) => {
                    if (obj.menu_code === 'SYS_MANAGE') {
                      acc.push(...obj.children)
                    } else {
                      acc.push(obj)
                    }
                    return acc
                  }, [])
              )}
            </Menu>
          </Sider>
        )}

        <Layout id="right_content" style={{ position: 'relative' }}>
          <Header>
            <span className="left-title">
              {getTitleName() !== 'undefined'
                ? getTitleName()
                : '吾道科技管理系统'}
            </span>

            <>
              <div className="ant-header-right">
                <img
                  width={40}
                  height={40}
                  style={{ borderRadius: '50%' }}
                  src={userInfo?.pf_photo ? userInfo?.pf_photo : photo}
                  alt=""
                />
                <span className="sys-pepo">{userInfo?.show_name}</span>
                <Popconfirm
                  placement="topRight"
                  title=" 你确定要退出吗？"
                  onConfirm={confirm}
                  okText="确定"
                  cancelText="取消"
                >
                  <a href="">退出</a>
                </Popconfirm>
                <span>
                  <MyIcon type="icon-a-guangbo1"></MyIcon>
                </span>
                <span className="setting">
                  <Popover
                    onVisibleChange={() => setIspopover(!ispopover)}
                    visible={ispopover}
                    title="设置"
                    trigger="click"
                    content={<a onClick={showModal}>修改密码</a>}
                  >
                    <MyIcon
                      type="icon-a-icon_shezhi1"
                      onClick={() => setIspopover(true)}
                    ></MyIcon>
                  </Popover>
                </span>
                <span>
                  <Popover
                    visible={helpPopover}
                    trigger="click"
                    content={
                      <div style={{ width: 60 }}>
                        <a
                          onClick={() => {
                            window.open(
                              'https://guodongbond.com/znsb/user-guide.php',
                              '_bank'
                            )
                          }}
                        >
                          帮助中心
                        </a>
                        <a
                          onClick={() => {
                            window.open('/legal-agreement/tos.html', '_bank')
                          }}
                        >
                          服务协议
                        </a>
                        <a
                          onClick={() => {
                            window.open(
                              '/legal-agreement/privacy.html',
                              '_bank'
                            )
                          }}
                        >
                          隐私协议
                        </a>
                        <a
                          onClick={() => {
                            window.open('/legal-agreement/about.html', '_bank')
                          }}
                        >
                          联系方式
                        </a>
                        <a
                          onClick={() => {
                            dispatch(
                              getUpdateLogList({
                                ordering: '-update_date',
                                sys_manage: true,
                              })
                            )
                            setHelpPopover(false)

                            setWatch(true)
                          }}
                        >
                          更新日志
                        </a>
                      </div>
                    }
                  >
                    <MyIcon
                      type="icon-a-icon_yiwenkongxin1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setHelpPopover(!helpPopover)
                      }}
                    ></MyIcon>
                  </Popover>
                </span>
              </div>
            </>
          </Header>

          <Content className="site-layout-background">
            <Switch key={location.key}>
              <PrivateRoute exact path="/home" component={Home}></PrivateRoute>
              <PrivateRoute
                path="/home/intellectwrite"
                component={IntellectWrite}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/compmanage"
                component={CompManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/usermanage"
                component={Usermanage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/orgmanage"
                component={OrgManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/menumanage"
                component={MenuManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/fucmanage"
                component={FucManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/rolemanage"
                component={RoleManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/datadictionary"
                component={DataDictionary}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/configmanage"
                component={ConfigManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/productconfgtemp"
                component={ProductConfgTemp}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/journalmanage"
                component={JournalManage}
              ></PrivateRoute>
              <PrivateRoute
                path="/home/sysmanage/updatelogmanage"
                component={UpdateLogManage}
              ></PrivateRoute>
              <Route path="/help" component={Help}></Route>
              <Route path="/home/nofunc" component={Nofunc}></Route>
              <Route
                path="/legal-agreement/tos.html"
                component={ServiceAgreement}
              ></Route>
              <Route
                path="/legal-agreement/privacy.html"
                component={PrivacyAgreement}
              ></Route>
              <Route
                path="/legal-agreement/about.html"
                component={ContactInformation}
              ></Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
      {/* 设置密码 */}
      <Modal
        wrapClassName="passwordModal"
        centered
        onCancel={() => setIsModal(false)}
        onOk={changePass}
        title="修改密码"
        visible={isModal}
        getContainer={false}
        okText="修改"
        cancelText="取消"
        okType="primary"
      >
        <Form
          className="reset_form"
          autoComplete="off"
          form={form}
          labelAlign="left"
          autoComplete="off"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
        >
          <Form.Item
            label="原密码"
            name="old_password"
            rules={[
              {
                required: true,
                message: '原密码不能为空',
              },
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
          <Form.Item
            label="新的密码"
            name="new_password_1"
            rules={[
              {
                required: true,
                message: '新密码不能为空',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve()
                  }
                  if (value?.length < 8 || value?.length > 16) {
                    return Promise.reject(
                      new Error('请输入8-16位数字字母组合的密码')
                    )
                  }
                  let reg =
                    /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,16}$/
                  if (!reg.test(value)) {
                    return Promise.reject(
                      new Error('请输入8-16位数字字母组合的密码')
                    )
                  }
                  if (value === getFieldValue('old_password')) {
                    return Promise.reject(new Error('新的密码不能和原密码一样'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
          <Form.Item
            label="确认新密码"
            name="new_password_2"
            rules={[
              {
                required: true,
                message: '确认密码不能为空',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve()
                  }
                  if (value?.length < 8 || value?.length > 16) {
                    return Promise.reject(
                      new Error('请输入8-16位数字字母组合的密码')
                    )
                  }
                  const new_password_1 = getFieldValue('new_password_1')
                  if (new_password_1 !== value) {
                    return Promise.reject(new Error('两次输入的密码不一致'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password></Input.Password>
          </Form.Item>
        </Form>
      </Modal>
      <Drawer
        destroyOnClose={true}
        width={624}
        title="更新日志"
        placement="right"
        onClose={() => setWatch(false)}
        visible={watch}
      >
        <div className="msg">{updatelogList[0]?.sys_name}</div>

        <div
          className="drawer_body"
          style={{ minHeight: 'calc(100% - 80px)', marginTop: 20 }}
        >
          <div
            style={{
              height: 'calc(100% - 40px)',
              overflow: 'auto',
              paddingRight: '24px',
            }}
          >
            {updatelogList.map((item) => (
              <div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ color: '#006AB2' }}>{item.update_date}</div>
                  <div style={{ color: '#0068B2' }}>{item.ver_no}</div>
                </div>
                <div
                  style={{ color: '#535353', margin: '16px 0px' }}
                  dangerouslySetInnerHTML={{ __html: item.update_log || '-' }}
                ></div>
                <hr style={{ border: '1px dashed #D8D8D8' }} />
                <br />
              </div>
            ))}
          </div>
        </div>
        <MyButton
          type="default"
          className=" ant-spaces"
          onClick={() => setWatch(false)}
        >
          关闭
        </MyButton>
      </Drawer>
    </div>
  )
}
